<template>
    <div class="d-flex flex-column flex-root vh100">
			<div class="login login-5 login-signin-on d-flex flex-row-fluid" id="kt_login">
				<div class="d-flex flex-center bgi-size-cover bgi-no-repeat flex-row-fluid"  v-bind:style="{ 'background-image': 'url(' + bg_image + ')' }">
					<div class="login-form text-center text-white p-7 position-relative overflow-hidden">
						<div class="d-flex flex-center mb-15">
							<a href="#">
								<img src="@/assets/images/logo-big.png" class="max-h-75px" alt="" />
							</a>
						</div>
						<div class="login-signin">
							<div class="mb-20">
								<h3 class="font-weight-normal">Reset Password</h3>
								<p class="">Enter your new password</p>
							</div>
        					<Message  v-if="status == 0" severity="error">{{ message }}</Message>
        					<Message  v-if="status == 1" severity="success">Your password was successfully updated.
                                <a href="/" class="mt-5"> Login Here</a>
                            </Message>
							<form class="form" @submit.prevent="reset"  method="post" id="kt_login_signin_form">
								<div class="form-group">
									<Password class="form-control h-auto rounded-pill border-0 py-4 px-8 padding-10" placeholder="Password" aria-placeholder="Password" v-model="password"  toggleMask></Password>
								</div>
                                <div class="form-group">
									<Password class="form-control h-auto rounded-pill border-0 py-4 px-8 padding-10" :feedback="false"  placeholder="Confirm password" aria-placeholder="Confirm password" v-model="re_password"></Password>									
								</div>
								<div class="form-group text-center mt-10">
									<button  :disabled="set_password_loading" type="submit" id="kt_login_signin_submit" class="btn btn-pill btn-primary opacity-90 px-15 py-3">
										<span v-if="!set_password_loading">Update Password</span>
										<span v-else> <i class="fas fa-spinner fa-spinner"></i> Updating</span>
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
</template>
<script>
import { ref, onMounted, reactive, inject, watch } from 'vue'
import  UsersNewPassword  from '@/composables/users/UsersNewPassword'
import Password from 'primevue/password';
import Message from 'primevue/message';
export default {
	components: { Message, Password },
    setup() {
		const { appRouter, router, route, constants, http, store } = inject('App')
		
		const bg_image = require('@/assets/themes/assets/media/bg/bg-2.jpg')
		const password = ref('')
        const re_password = ref('')
        const code = ref('')
        const { set_password_loading, message, status, setPassword } = UsersNewPassword(http, constants)

		const vm = reactive({
			loading: false
		})
	    function reset() {
			setPassword(password.value, re_password.value, code.value)
		}
		watch(set_password_loading, (n, o) => {
            if (set_password_loading.value == false) {

            }
        });
		onMounted(() => {
            let uri = window.location.search.substring(1); 
            let params = new URLSearchParams(uri);
            code.value = params.get('q')
        })
		return {
             bg_image,
			 password,
             re_password,
			 vm, 
             message,
			 set_password_loading,
			 status,
			 reset,
             code
		}
	},
	mounted() {
		
	}
}
</script>
<style scoped src="@/assets/themes/assets/css/pages/login/classic/login-5.css"></style> 