import { ref } from 'vue'
export default function UsersNewPassword(http, constant) {
    let set_password_loading = ref(false)
    let status = ref(-1)
    const message = ref('')
    
    async function setPassword (password, re_password, code) {
        set_password_loading.value = true
        status.value = -1
        message.value = '' 
        try {
            const f = new URLSearchParams()
            f.append('password', password)
            f.append('re_password', re_password)
            f.append('code', code)
            const response = await http.post(constant.USERS_NEW_PASSWORD, f)
            
            status.value = response.data.status
            message.value = response.data.error
            
            set_password_loading.value = false
        } catch (err) {
            set_password_loading.value = false
        }
    }
    return {
        set_password_loading, message, status, setPassword
    }
}